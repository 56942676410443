<template>
  <el-dialog
    :title="form.id ? '修改组织' : '添加组织'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="field-class-save-form"
      label-width="100px"
    >
      <el-form-item
        label="组织名称"
        prop="orgName"
        :rules="[ { required: true, message: '组织名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.orgName"
          type="text"
        />
      </el-form-item>
      <el-form-item
          label="排序"
      >
        <el-input
            v-model.number="form.sortNo"
            type="number"
        />
      </el-form-item>
      <el-form-item
        label="组织职责"
      >
        <el-input
          v-model="form.responsibility"
          :autosize="{ minRows: 10}"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { error, success } from '@core/utils/utils'
import { saveOrUpdateOrg, GetOrgById } from '@/api/org/org'

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      orgOptions: [],
      form: {
        id: 0,
        code: '',
        sortNo: 0,
        orgName: '',
        responsibility: '',
        parentId: 0,
        templateId: Number(this.$route.query.id),
      },
    }
  },
  methods: {
    getDetail() {
      GetOrgById({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
        }
      })
    },
    dialogOpen() {
      if (this.form.id) {
        this.getDetail()
      }
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.parentId = this.form.parentId ? this.form.parentId : 0
          saveOrUpdateOrg(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
    dialogClose() {
      this.form = {
        id: 0,
        code: '',
        orgName: '',
        sortNo: 0,
        responsibility: '',
        parentId: 0,
        templateId: Number(this.$route.query.id),
      }
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style scoped>
</style>
