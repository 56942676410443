import { get, post } from '@/libs/axios'

export const saveOrUpdateOrgTemplate = params => {
  const submitUrl = params.id ? '/template/org/UpdateOrgTemplate' : '/template/org/CreateOrgTemplate'
  return post(submitUrl, params)
}
export const saveOrUpdateOrgRole = params => {
  const submitUrl = params.id ? '/template/org/UpdateRole' : '/template/org/CreateRole'
  return post(submitUrl, params)
}
export const saveOrUpdateOrg = params => {
  const submitUrl = params.id ? '/template/org/UpdateOrg' : '/template/org/CreateOrg'
  return post(submitUrl, params)
}
export const GetOrgTemplateList = () => get('/template/org/GetOrgTemplateList')
export const GetOrgById = params => post('/template/org/GetOrgById', params)
export const FinishTemplate = params => post('/template/org/FinishOrgTemplate', params)
export const GetRoleById = params => post('/template/org/GetRoleById', params)
export const DelOrg = params => post('/template/org/DelOrg', params)
export const DelRole = params => post('/template/org/DelRole', params)
export const GetOrgTemplateById = params => post('/template/org/GetOrgTemplateById', params)
export const GetOrgList = params => post('/template/org/GetOrgList', params)
export const GetRoleList = params => post('/template/org/GetRoleList', params)
