<template>
  <div>
    <div>
      <b-card>
        <div class="flex-between">
          <el-descriptions>
            <el-descriptions-item label="模板名称">
              {{ templateName }}
            </el-descriptions-item>
            <el-descriptions-item label="模板编号">
              {{ templateCode }}
            </el-descriptions-item>
          </el-descriptions>
          <el-button
            size="small"
            @click="$router.push({ path: '/org'})"
          >
            返 回
          </el-button>
          <el-button
            v-if="status !== 2"
            size="small"
            type="primary"
            @click="finishStatus"
          >
            标记完成
          </el-button>
        </div>
      </b-card>
      <b-row class="match-height">
        <b-col md="6">
          <b-card title="隐私组织">
            <div class="text-right mb10">
              <el-button
                v-if="status !== 2"
                size="small"
                type="primary"
                @click="orgSaveDialogShow(1)"
              >
                新增
              </el-button>
            </div>
            <el-table
              ref="elTable"
              v-loading="orgListLoading"
              class="hight-table"
              :data="orgList"
              border
              :current-row-key="query.orgId"
              style="width: 100%;"
              :highlight-current-row="true"
              row-key="id"
              @row-click="rowClick"
            >
              <el-table-column
                prop="orgName"
                :show-overflow-tooltip="true"
                label="组织名称"
              />
              <el-table-column
                prop="responsibility"
                :show-overflow-tooltip="true"
                label="组织职责"
              />
              <el-table-column
                prop="sortNo"
                label="排序号"
              />
              <el-table-column
                v-if="status !== 2"
                label="操作"
                header-align="center"
                align="center"
                width="120"
              >
                <template v-slot="scope">
                  <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click="orgSaveDialogShow(2,scope.row)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    type="primary"
                    class="mr10"
                    @click="deleteHandle(scope.row.id)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card title="组织角色">
            <div class="text-right mb10">
              <el-button
                v-if="status !== 2"
                size="small"
                type="primary"
                @click="orgRoleDialogShow(1)"
              >
                新增
              </el-button>
            </div>
            <el-table
              v-loading="orgListLoading"
              :data="orgRoleList"
              border
              style="width: 100%;"
            >
              <el-table-column
                prop="roleName"
                :show-overflow-tooltip="true"
                label="组织角色"
              />
              <el-table-column
                prop="responsibility"
                :show-overflow-tooltip="true"
                label="角色职责"
              />
              <el-table-column
                prop="sortNo"
                label="排序号"
              />
              <el-table-column
                v-if="status !== 2"
                label="操作"
                header-align="center"
                align="center"
                width="120"
              >
                <template v-slot="scope">
                  <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click="orgRoleDialogShow(2,scope.row)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    type="primary"
                    class="mr10"
                    @click="deleteRole(scope.row.id)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
          </b-card>
        </b-col>
      </b-row>
      <OrgItemSave
        ref="orgItemSave"
        @handleFilter="getOrgList"
      />
      <OrgRoleSave
        ref="orgRoleSave"
        @handleFilter="getOrgRoleList"
      />
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import {
  DelOrg, GetOrgList, GetRoleList, DelRole, GetOrgTemplateById, FinishTemplate,
} from '@/api/org/org'
import OrgItemSave from '@/views/template/org/OrgItemSave.vue'
import OrgRoleSave from '@/views/template/org/OrgRoleSave.vue'
// eslint-disable-next-line import/no-cycle
import { error, success } from '@core/utils/utils'

export default {
  components: { OrgItemSave, OrgRoleSave },
  data() {
    return {
      templateName: '',
      templateCode: '',
      orgListLoading: false,
      orgRoleLoading: false,
      orgList: [],
      orgRoleList: [],
      status: 0,
      templateId: Number(this.$route.query.id),
      query: {
        orgId: 0,
      },
    }
  },
  created() {
    this.getDetail()
    this.getOrgList()
  },
  mounted() {
    this.navActiveInit(1, '隐私组织', '模板管理')
    // this.$refs.elTable.setCurrentRow(0)
  },
  destroyed() {
    this.navActiveInit(2, '隐私组织', '模板管理', '/org')
  },
  methods: {
    finishStatus() {
      this.$confirm('确认标记完成吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        FinishTemplate({ templateId: this.templateId })
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.$router.push({ path: '/org' })
              // this.back()
            } else {
              error(resData.msg)
            }
          })
      })
    },
    getDetail() {
      GetOrgTemplateById({ id: this.templateId }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.templateName = resData.data.name
          this.templateCode = resData.data.code
          this.status = resData.data.status
        }
      })
    },
    orgSaveDialogShow(type, row) {
      this.$refs.orgItemSave.dialogVisible = true
      this.$refs.orgItemSave.orgOptions = this.orgList
      if (type === 2) {
        this.$refs.orgItemSave.form.id = row.id
      }
    },
    orgRoleDialogShow(type, row) {
      this.$refs.orgRoleSave.dialogVisible = true
      this.$refs.orgRoleSave.orgOptions = this.orgList
      if (type === 2) {
        this.$refs.orgRoleSave.form.id = row.id
      }
    },
    deleteRole(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const ids = [id]
        DelRole({ ids })
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.getOrgRoleList()
            } else {
              error(resData.msg)
            }
          })
      })
    },
    deleteHandle(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const ids = [id]
        DelOrg({ ids })
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.getOrgList()
            } else {
              error(resData.msg)
            }
          })
      })
    },
    getOrgList() {
      this.orgListLoading = true
      GetOrgList({ templateId: this.templateId }).then(res => {
        this.orgListLoading = false
        const resData = res.data
        if (resData.code === 0) {
          this.orgList = resData.data
          if (this.orgList.length > 0) {
            this.query.orgId = this.orgList[0].id
            this.getOrgRoleList()
          }
        }
      }).catch(() => {
        this.orgListLoading = false
      })
    },
    getOrgRoleList() {
      this.orgRoleLoading = true
      GetRoleList({ orgId: this.query.orgId }).then(res => {
        this.orgRoleLoading = false
        const resData = res.data
        if (resData.code === 0) {
          this.orgRoleList = resData.data
        }
      }).catch(() => {
        this.orgRoleLoading = false
      })
    },
    rowClick(row) {
      this.query.orgId = row.id
      this.getOrgRoleList()
    },
  },
}
</script>
<style lang="scss">
.hight-table{
  .current-row td{
    background: #0d40b2 !important;
    color: #ffffff !important;
    i{
      color: #ffffff !important;
    }
  }
}

</style>
